/* Kaptest Website Breakpoint values- Device Window Sizes */
:root {
  /* colors */

  /* unit */

  /* spacing */

  /* fonts and typography */

  /* breakpoints */

  /* icon sizes */

  /* avatar */

  /* chips */

  /* shadows */

  /* zdepth shadows */

  /* animations */

  /* z-index */

  /* reset */
}
.pagination {
  display: flex;
}
.page {
  align-content: center;
  align-items: center;
  background-color: transparent;
  border: 0;
  border-radius: 50%;
  color: rgb(120, 37, 255);
  cursor: pointer;
  display: inline-block;
  flex-direction: row;
  font-size: 16px;
  font-weight: normal;
  height: 32px;
  justify-content: center;
  letter-spacing: 0;
  line-height: 32px;
  min-width: 32px;
  outline: none;
  padding: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition:
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    border-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap
}
.page:focus:not(.active),
  .page:hover:not(.active) {
    color: rgb(82, 0, 216);
  }
.active {
  background: rgb(120, 37, 255);
  color: rgb(255, 255, 255);
  font-weight: 700;
}
.delimiter {
  composes: page;
  cursor: default;
}
.next,
.prev {
  align-items: center;
  composes: page;
  display: flex;
  height: 32px;
  justify-content: center
}
.next[disabled], .prev[disabled] {
    color: rgba(0, 0, 0, 0.26);
    cursor: auto;
    pointer-events: none
  }
.next[disabled]:before, .prev[disabled]:before {
      border-right-color: rgba(0, 0, 0, 0.26);
      border-top-color: rgba(0, 0, 0, 0.26);
    }
.next:before, .prev:before {
    border: 2px solid rgb(120, 37, 255);
    border-bottom-color: transparent;
    border-left-color: transparent;
    content: '';
    display: block;
    height: 10px;
    width: 10px;
  }
.next:before {
  margin-left: 5px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.prev:before {
  margin-right: 5px;
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
}
